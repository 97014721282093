@import "./bootstrap/scss/functions";
@import "./bootstrap/scss/variables";
@import "./fonts/rubik/stylesheet.css";

#root,
#content,
body {
  min-height: 100vh;
}

#content {
  height: 100%;
  overflow: hidden;
}

.content-main {
  position: relative;
  min-height: calc(100vh - 70px);
}

.tabbed-content {
  position: relative;
  height: 500px;
  min-height: calc(100vh - 112px);
}

.monospace {
  font-family: monospace;
}

.box-fit-wrapper {
  position: relative;
  .box-fit-overflow {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0;
    left: 0;
    overflow: auto;
    will-change: transform;
  }
}

.dropdown-toggle > * {
  pointer-events: none; // make sure that the anchor is clicked instead of child elements
}

.with-arrow {
  position: relative;
  // &::before,
  // &::after {
  //   left: 100%;
  //   top: 50%;
  //   border: solid transparent;
  //   content: " ";
  //   height: 0;
  //   width: 0;
  //   position: absolute;
  //   pointer-events: none;
  //   z-index: 1;
  // }
  // &::after {
  //   border-color: transparent;
  //   border-left-color: #fff;
  //   border-width: 15px;
  //   margin-top: -15px;
  // }
  // &::before {
  //   border-color: transparent;
  //   border-left-color: #dee2e6;
  //   border-left-color: $border-color; PA FIX NITO THANKS, HINDI KO MA IMPORT YUNG VARIABLE
  //   border-width: 16px;
  //   margin-top: -16px;
  // }
}
::-webkit-scrollbar-track {
  background-color: #e6e5e1;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #e6e5e1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  // background-color: $gray-500; PA FIX NITO THANKS, HINDI KO MA IMPORT YUNG VARIABLE
  border-radius: 10px;
}

.detail-header-box {
  position: relative;
  min-height: 82px;
  .detail-header-action {
    position: absolute;
    top: 0;
    right: 0;
  }
  .detail-header-photo {
    position: relative;
    // margin-bottom: -26px;
    min-height: 102px;
    max-height: 102px;
    min-width: 102px;
    max-width: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 92px;
      max-width: 92px;
    }
  }
}

.detail-tabs {
  margin-top: -5px;
  & :first-child.nav-item {
    margin-left: 130px;
  }
}

table.table-fixed-header {
  position: relative;
  border-collapse: collapse;
  th,
  td {
    padding: 0.25rem;
  }
  th {
    background: #fff;
    position: sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
}

.table-striped-column {
  tbody th:nth-of-type(even),
  tbody td:nth-of-type(even) {
    //background-color: $table-dark-accent-bg; PA FIX NITO THANKS, HINDI KO MA IMPORT YUNG VARIABLE
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.img-holder {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  overflow: hidden;
}
.img-xs {
  max-height: 35px;
  max-width: 35px;
}

.ReactVirtualized__Table__headerRow {
  background: #eaebef;
}

.ReactVirtualized__Table__Grid {
  outline: none;
}

.ReactVirtualized__Grid__innerScrollContainer {
  .ReactVirtualized__Table__row:nth-of-type(even) {
    background: #fbfcfe;
  }
}

.modal-open .modal {
  will-change: transform;
}

#clipboard-input {
  opacity: 0;
  position: absolute;
  // border: 0;
  // height: 0;
  // width: 0;
}

.draft-editor-body {
  min-height: 300px;
  border: 1px solid #c6c6c6;
  border-radius: 0.25rem;
  padding-left: 10px;
  padding-right: 10px;
}

.spinner-bar {
  zoom: 1; /* Increase this for a bigger symbole*/

  display: inline-block;

  width: 16px;
  height: 16px;

  animation: wait 0.8s steps(1, start) infinite;

  background: linear-gradient(
      0deg,
      #f4f5fa 1px,
      transparent 0,
      transparent 8px,
      #f4f5fa 8px
    ),
    /* 6  */
      linear-gradient(90deg, #f4f5fa 1px, #f6f9fb 0, #f6f9fb 3px, #f4f5fa 3px),
    linear-gradient(
      0deg,
      #ececf5 1px,
      transparent 0,
      transparent 8px,
      #ececf5 8px
    ),
    /* 5  */
      linear-gradient(90deg, #ececf5 1px, #f2f3f9 0, #f2f3f9 3px, #ececf5 3px),
    linear-gradient(
      0deg,
      #e7eaf4 1px,
      transparent 0,
      transparent 8px,
      #e7eaf4 8px
    ),
    /* 4  */
      linear-gradient(90deg, #e7eaf4 1px, #eef1f8 0, #eef1f8 3px, #e7eaf4 3px),
    linear-gradient(
      0deg,
      #b9bedd 1px,
      transparent 0,
      transparent 10px,
      #b9bedd 10px
    ),
    /* 3  */
      linear-gradient(90deg, #b9bedd 1px, #d0d5e8 0, #d0d5e8 3px, #b9bedd 3px),
    linear-gradient(
      0deg,
      #9fa6d2 1px,
      transparent 0,
      transparent 15px,
      #9fa6d2 15px
    ),
    /* 2  */
      linear-gradient(90deg, #9fa6d2 1px, #c0c5e1 0, #c0c5e1 3px, #9fa6d2 3px),
    linear-gradient(
      0deg,
      #8490c6 1px,
      transparent 0,
      transparent 15px,
      #8490c6 15px
    ),
    /* 1  */
      linear-gradient(90deg, #8490c6 1px, #aeb5da 0, #aeb5da 3px, #8490c6 3px);

  background-repeat: no-repeat;

  background-size: 4px 9px, /* 6 */ 4px 9px, 4px 9px, /* 5 */ 4px 9px, 4px 9px,
    /* 4 */ 4px 9px, 4px 11px, /* 3 */ 4px 11px, 4px 16px, /* 2 */ 4px 16px,
    4px 16px, /* 1 */ 4px 16px;

  background-position: -4px 3px, -4px 3px, -4px 3px, -4px 3px, -4px 3px,
    -4px 3px, -4px 2px, -4px 2px, -4px 0, -4px 0, -4px 0, -4px 0;
}

@keyframes wait {
  12.5% {
    background-position: -4px, /* 6 */ -4px, -4px, /* 5 */ -4px, -4px,
      /* 4 */ -4px, -4px, /* 3 */ -4px, -4px, /* 2 */ -4px, 0, /* 1 */ 0;
  }
  25% {
    background-position: -4px, /* 6 */ -4px, -4px, /* 5 */ -4px, -4px,
      /* 4 */ -4px, -4px, /* 3 */ -4px, 0, /* 2 */ 0, 6px, /* 1 */ 6px;
  }
  37.5% {
    background-position: -4px, /* 6 */ -4px, -4px, /* 5 */ -4px, -4px,
      /* 4 */ -4px, 0, /* 3 */ 0, 6px, /* 2 */ 6px, 12px, /* 1 */ 12px;
  }
  50% {
    background-position: -4px, /* 6 */ -4px, -4px, /* 5 */ -4px, 0, /* 4 */ 0,
      6px, /* 3 */ 6px, 12px, /* 2 */ 12px, -4px, /* 1 */ -4px;
  }
  62.5% {
    background-position: -4px, /* 6 */ -4px, 0, /* 5 */ 0, 6px, /* 4 */ 6px,
      12px, /* 3 */ 12px, -4px, /* 2 */ -4px, -4px, /* 1 */ -4px;
  }
  75% {
    background-position: 0, /* 6 */ 0, 6px, /* 5 */ 6px, 12px, /* 4 */ 12px,
      -4px, /* 3 */ -4px, -4px, /* 2 */ -4px, -4px, /* 1 */ -4px;
  }
  87.5% {
    background-position: 6px, /* 6 */ 6px, 12px, /* 5 */ 12px, -4px,
      /* 4 */ -4px, -4px, /* 3 */ -4px, -4px, /* 2 */ -4px, -4px, /* 1 */ -4px;
  }
  100% {
    background-position: 12px, /* 6 */ 12px, -4px, /* 5 */ -4px, -4px,
      /* 4 */ -4px, -4px, /* 3 */ -4px, -4px, /* 2 */ -4px, -4px, /* 1 */ -4px;
  }
}

.editable-label {
  .editable-action {
    opacity: 0;
    margin-left: 2px;
  }
  &:hover {
    .editable-action {
      opacity: 1;
    }
  }
}

textarea {
  will-change: transform;
  resize: none;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-bottom: 0;

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    z-index: 1;
    .label-on {
      position: absolute;
      top: 8px;
      z-index: 2;
      left: 4px;
      font-size: 12px;
    }
    .label-off {
      position: absolute;
      top: 8px;
      z-index: 2;
      right: 4px;
      font-size: 12px;
    }
  }

  .slider .toggle {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    z-index: 3;
    i {
      color: #a0a1ac;
      position: absolute;
      top: 4px;
      left: 6px;
    }
  }

  input {
    opacity: 0;
  }

  input + .slider {
    .label-on {
      opacity: 0;
    }
    .label-off {
      opacity: 1;
    }
  }

  input:checked + .slider {
    background-color: #2196f3;
    .label-on {
      opacity: 1;
    }
    .label-off {
      opacity: 0;
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider .toggle {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round .toggle {
    border-radius: 50%;
  }
}

.action-hider {
  &:hover {
    .action {
      opacity: 1;
    }
  }
  .action {
    opacity: 0;
  }
}

#my-modal-marker {
  position: relative;
  z-index: 1000;
}

#my-toast-marker {
  position: relative;
  z-index: 1001;
}

.login-account {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../assets/img/login-bg.svg") no-repeat center center;
  background-size: cover;
  .login-form {
    width: 350px;
    margin: auto;
    .login-header {
      text-align: center;
      .logo {
        max-width: 190px;
      }
      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 60px;
        margin: 0;
      }
      .subtitle {
        font-size: 18px;
        line-height: 24px;
        margin: 0;
      }
      margin-bottom: 10px;
    }
    .login-box {
      .login-input {
        background: #fff;
        padding: 6px 8px;
        border: 1px solid #808c99;
        border-radius: 8px;
        .form-label {
          font-size: 12px;
          line-height: 16px;
          margin: 0;
          text-transform: uppercase;
          font-weight: 600;
        }
        .form-control {
          padding: 0;
          line-height: 24px;
          font-size: 16px;
          height: 24px;
          border: none;
          appearance: none;
          background: #fff;
          outline: 0;
          box-shadow: none;
          &:hover,
          &:active,
          &:focus {
            background: #fff;
          }
        }
      }
    }
  }
}

.floating-form {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background: #ffffff;
  padding: 10px;
}

.floating {
  transition: background-color 0.2s ease;
  outline: none;

  &:hover {
    .floating-input {
      border-color: #e3e3e3;
    }
  }

  &:hover,
  &:focus-within {
    background-color: #ffffff;
  }
}

.floating-input {
  padding: 12px 0 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  outline: none;

  &.form-control {
    height: 34px;
    box-shadow: none;
    border: 0;
    border-radius: 0;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  &:focus + .floating-label::after {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  &:placeholder-shown + .floating-label::before {
    transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
    line-height: 32px;
  }

  &:focus + .floating-label::before {
    color: #808c99;
  }
}

.floating-label {
  display: block;
  position: relative;
  max-height: 0;
  pointer-events: none;
  margin: 0;

  &::before {
    color: #e3e3e3;
    font-weight: bold;
    content: attr(data-content);
    display: inline-block;
    filter: blur(0);
    backface-visibility: hidden;
    transform-origin: left top;
    transition: transform 0.2s ease;
    left: 0px;
    position: relative;
  }

  // &::after {
  // 	bottom: 1rem;
  // 	content: "";
  // 	height: 0.1rem;
  // 	position: absolute;
  // 	transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
  // 	opacity: 0;
  // 	left: 0;
  // 	top: 100%;
  // 	margin-top: -0.1rem;
  // 	transform: scale3d(0, 1, 1);
  // 	width: 100%;
  // 	// background-color: #808c99;
  // }
}

.floating-label::before,
.floating-input:focus + .floating-label::before {
  transform: translate3d(0, -38px, 0) scale3d(0.6, 0.6, 1);
  line-height: 12px;
}

.hidden.-visually {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.nav-active {
  color: $primary !important;
  background-color: $gray-200;
  border-radius: 13px 0 0 13px;
}

.modal {
  .lightbox-wrapper {
    .modal-content {
      background: none;
      border: none;
      .lightbox-image {
        img {
          width: 100%;
        }
      }
    }
  }
  backdrop-filter: blur(3px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.map-center-pin {
  position: absolute;
  /*url of the marker*/
  background-image: url("./img/marker.png");
  /*center the marker*/
  top: 50%;
  left: 50%;

  z-index: 1;
  /*fix offset when needed*/
  margin-left: -10px;
  margin-top: -34px;
  /*size of the image*/
  height: 34px;
  width: 20px;

  cursor: pointer;
  &.location-pin {
    background-repeat: no-repeat;
    background-size: cover;
    width: 40px;
    height: 50px;
    margin-left: -20px;
    margin-top: -50px;
  }
}

.list-group-item {
  border-left: none;
  border-right: none;
  .title {
    font-size: 13px;
  }
  .subtext {
    font-size: 11px;
  }
  &:first-child,
  &:last-child {
    border-radius: 0;
  }
  &.active {
    background-color: $gray-300;
    border-color: $gray-300;
    color: $gray-800;
  }
}
.table td {
  &.label {
    font-size: 14px;
    color: $gray-600;
  }
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: $gray-100;
}
.badge {
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 11px;
}

.maps-searchbox {
  box-sizing: border-box;
  border: 1px solid transparent;
  // width: 240px;
  width: 340px;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  bottom: 28px;
  left: 8px;
  &.top {
    position: absolute;
    top: 8px;
    bottom: initial;
  }
}

.blur-loader {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff3;
  backdrop-filter: blur(2px);
  color: #ffffff;
  font-size: 20px;
  text-shadow: 0px 0px 11px #000;
}

.phone-bg {
  position: relative;
  background: url("../assets/img/img-phone.png");
  background-size: cover;
  .phone-content {
    position: absolute;
    top: 60px;
    width: calc(100% - 60px);
    left: 30px;
    .phone-message {
      background: #ddd;
      color: #333;
      border-radius: 16px;
    }
  }
}

.user-contact-tracing {
  min-height: 400px;
  .status-legend {
    font-size: 12px;
    img {
      height: 20px !important;
      width: 20px;
    }
  }
}

// ::-webkit-scrollbar {
//   width: 0px!important;
// }

.react-tel-input {
  .selected-flag {
    position: relative;
    width: 38px;
    height: 100%;
    padding: 0 0 0 8px;
  }
}

.form-icon-right {
  position: relative;
  & > .right {
    position: absolute;
    top: 0;
    padding: 6.5px 4.5px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 600;
    color: $primary;
    height: 41px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > .right {
    right: 1px;
  }

  & > .react-tel-input .form-control {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 6px;
    height: 38px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    padding-right: 10px;
    line-height: 24px;
    font-size: 14px;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
      border: 1px solid #abaab1;
    }
    &::placeholder {
      color: #adb5bd;
    }
  }
  & > .form-custom {
    padding-right: 30px;
  }
}

.form-control {
  &.small {
    height: 22px;
    padding: 0 8px;
  }
}

.img-circle-border {
  border-radius: 50%;
  overflow: hidden;
}

.alert-box-wrapper {
  max-width: 400px;
}

.pac-container {
  z-index: 2000;
}

.modal-dialog.modal-full {
  position: fixed;
  max-width: 100% !important;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 0px;
  .modal-content {
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
  }
}

.table-header-small {
  .ReactVirtualized__Table__headerColumn {
    font-size: 12px;
    font-weight: normal;
  }
}

.title-box {
  margin-bottom: 10px;
  display: block;
}

.animate {
  &.bounce {
    animation: bounce 1s infinite;
    -webkit-animation: bounce 1s infinite;
    -moz-animation: bounce 1s infinite;
    -o-animation: bounce 1s infinite;
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-10px);
  }
  60% {
    -moz-transform: translateY(-5px);
  }
}

@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-10px);
  }
  60% {
    -o-transform: translateY(-5px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.ReactVirtualized__Table__row {
  outline: none;
}

.react-select-sm {
  min-width: 130px;
  width: 100%;
}

.underline {
  border-bottom: 1px solid;
}

.print {
  #wrapper {
    display: none;
  }
}

.compressed {
  .compressable {
    font-size: 10.9px;
    label {
      padding-top: 3px;
    }
  }
}

#case-location-case-trace-overlay {
  position: relative;
  background-color: #fff2;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(3px);
  transition: all 150ms ease-in;
  &.loading {
    backdrop-filter: blur(0px);
    background-color: #fff0;
  }
}

.blinking {
  animation: blinkingText 1.2s infinite;
  transition: opacity ease-in-out 0.2s;
}
@keyframes blinkingText {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dropzone {
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  /* margin-top: 100px; */
  padding: 20px;
  cursor: pointer;
  /* height: 150px; */
  /* width: 400px; */
  border: 2px dashed #b5adad;
  outline: none;
}

.date-filter-container #day-picker-date {
  margin-right: 0 !important;
}

.index-circle {
  border-radius: 9999px;
  position: absolute;
  padding: 5px;
  top: -15px;
  left: -6px;
  background: #fff;
  border: 3px solid #133963 !important;
  font-weight: bold;
}

.bg-white-primary {
  background-color: #f5f5f9 !important;
}

.no-underline {
  text-decoration: none !important;
}
