// Global component styles

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
}

// a {
//   transition-duration: 0.4s;
//   &:focus,
//   &:hover {
//     transition-duration: 0.4s;
//     outline: none;
//   }
// }

// Main page wrapper
#wrapper {
  display: flex;
  #content-wrapper {
    background-color: $gray-100;
    width: 100%;
    overflow-x: hidden;
    #content {
      flex: 1 0 auto;
    }
  }
  .rounded-wrapper {
    border-radius: 30px 0 0 30px;
  }
}

// Set container padding to match gutter width instead of default 15px
.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

// Scroll to top button
.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: $white;
  background: fade-out($gray-800, 0.5);
  line-height: 46px;
  &:focus,
  &:hover {
    color: white;
  }
  &:hover {
    background: $gray-800;
  }
  i {
    font-weight: 800;
  }
}

.btn {
  font-weight: 500;
  border-radius: 5px;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}
.btn-primary {
  // box-shadow: 0 4px 6px rgba($primary, 0.3), 0 1px 3px rgba(0, 0, 0, 0.08);
}
