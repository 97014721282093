@mixin nav-link-style {
  color: #313131;
  font-size: 1.2rem;
  font-weight: lighter;
  transform: scale(1, 0.9);
  display: inline-block;
  &:hover {
    text-decoration: none;
    transition: all 0.5s;
    color: $orange;
  }
}
.landing__nav {
  &-scroll-bg {
    background: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .landing__nav-logo-container {
      width: 4rem;
    }
  }
  &-parent-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    transition: all 0.5s;
  }
  &-container {
    width: 85%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    position: relative;
  }

  &-logo-container {
    width: 5rem;
    transition: all 0.5s;
  }

  &-logo {
    width: 100%;
  }
  // &-logo-container{}
  // &-logo{}
  &-ul-container {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin: 0;
    padding: 0;
  }
  &-li {
    list-style: none;
  }
  &-link {
    color: #313131;
    font-size: 1.2rem;
    font-weight: lighter;
    transform: scale(1, 0.9);
    display: inline-block;
    &:hover {
      text-decoration: none;
      transition: all 0.5s;
      color: $orange;
    }
  }
  &-phone-service {
    font-size: 1.1rem;
    font-weight: 100;
    color: $orange;
    border: 1px solid $orange;
    border-radius: 5rem;
    padding: 0.4rem 1.3rem;
    text-decoration: none !important;
    &:hover {
      transition: all 0.5s;
      color: #fff;
      background: $orange;
    }
  }

  &-mobile-btn-menu-container {
    display: none;
  }

  &-mobile-btn-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &-mobile-ul-container {
    display: none;
  }
  @media only screen and (max-width: 900px) {
    &-parent-container {
      background: #ffffff;
    }
    &-btn-desktop-container {
      display: none;
    }

    &-ul-container {
      display: none;
    }

    &-mobile {
      &-ul-container-scrolled {
        top: 5.8rem !important;
      }
      &-ul-container {
        display: unset;
        width: 100%;
        position: absolute;
        top: 6.8rem;
        left: 0;
        background: #fff;
        z-index: 9999 !important;
        border-top: 3px solid $orange;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 1rem;
        overflow: hidden;
      }
      &-li {
        width: 100%;
        list-style: none;
      }
      &-link {
        @include nav-link-style;
        width: 100%;
        padding: 1rem;
        &:hover {
          background: rgb(240, 240, 240);
        }
      }
      &-btn-container {
        flex-direction: column;
        align-items: unset;
        gap: unset;
      }
      &-btn-menu-container {
        display: unset;
        width: 3rem;
        border: none;
        outline: none;
        background: none;
      }
      &-btn-menu-icon {
        width: 100%;
      }
    }
  }
}
