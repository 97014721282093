.landing__home {
  &-why-container {
    padding: 6rem 0;
  }
  &-why-title {
    margin-bottom: 1rem !important;
  }
  &-why-img-parent-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
  }
  &-why-img-container {
    width: 100%;
    margin-bottom: 0;
  }
  &-why-img {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 2.9rem;
  }

  // media queries
  @media only screen and (max-width: 1000px) {
    &-why-img-parent-container {
      gap: 3rem;
    }
  }
  @media only screen and (max-width: 900px) {
    &-why-container {
      padding: 4rem 0;
    }
    &-why-img-parent-container {
      grid-template-columns: 1fr;
      justify-items: center;
      gap: 1.5rem;
    }
    &-why-img-container {
      width: 70%;
      margin-top: -2rem;
    }
  }
  @media only screen and (max-width: 400px) {
    &-why-img-container {
      width: 100%;
    }
  }
}
