.landing__footer {
  &-container {
    background: #ffd3ba;
    padding: 0.8rem 0;
  }
  &-paragraph-container {
    display: flex;
    justify-content: space-between;
    color: #010101;
    gap: 3rem;
    p {
      margin-bottom: 0;
    }
    & *:last-child {
      a {
        color: #2ea3f2;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  // MEDIA QUERIES
  @media only screen and (max-width: 800px) {
    &-paragraph-container {
      p {
        font-size: 0.7rem;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    &-paragraph-container {
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      text-align: center;
      line-height: 1;
      p {
        font-size: 0.7rem;
      }
    }
  }
}
