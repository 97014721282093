.scanner {
  &__container {
    display: flex;
    justify-items: center;
    justify-content: center;
    height: 90vh;
  }

  &__inner-container {
    width: 400px;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    height: 650px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    box-shadow: 5px 0 10px 0 rgb(227, 227, 227);
  }

  &__header-logo {
  }

  &__scan-icon-btn {
    position: absolute;
    top: 1rem;
    right: 0.9rem;
    background-color: transparent;
    border: none;
    outline: none;
    &:active,
    &:focus {
      outline: none;
    }
    &:hover {
      .scanner__scan-icon-tooltip {
        display: unset;
      }
    }
  }
  &__scan-icon {
    color: #fff;
    width: 1.5rem;
    height: 1.5rem;
  }

  &__scan-icon-tooltip {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 9.5rem;
    background: #000;
    color: #fff;
    border-radius: 4px;
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
    font-weight: 100;
    display: none;
  }

  &__main-logo {
    width: 30%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  &__card-initial-container {
    width: 100%;
    flex-grow: 1;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 600px;
  }
  &__card-initial-forms-container {
  }

  &__card-initial-instruction {
    font-size: 1rem;
  }

  &__input-qr-code-container {
    position: relative;
  }

  &__initial-qr-code-input {
    height: 38px !important;
    border-radius: 5px !important;
    margin-bottom: 2rem;
    &:hover ~ .scanner__initial-input-qr-code-tooltip {
      display: unset;
    }
  }

  &__initial-btn-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__initial-btn-submit,
  &__initial-btn-clear {
    width: 100%;
    padding: 0.6rem;
    border: 1px solid rgb(19, 57, 99);
    border-radius: 5px;
    transition: all 0.1s;
    font-size: 1rem;
    &:hover {
      outline-offset: 2px;
      outline: 2px solid rgb(19, 57, 99);
    }

    &:active,
    &:focus {
      outline-color: rgb(19, 57, 99);
    }
  }
  &__initial-btn-submit {
    background: rgb(19, 57, 99);
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    &:disabled {
      background: rgb(55, 70, 87);
      outline: none;
      cursor: not-allowed;
    }
    &:hover {
      .scanner__initial-btn-submit-tooltip {
        display: unset;
      }
    }
  }
  &__initial-btn-clear {
    background: #fff;
    color: rgb(19, 57, 99);
  }

  &__initial-btn-submit-tooltip,
  &__initial-input-qr-code-tooltip {
    position: absolute;
    left: 0.8rem;
    bottom: -0.5rem;
    background: #000;
    color: #fff;
    border-radius: 4px;
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
    font-weight: 100;
    display: none;
  }

  &__qr-container {
    flex-grow: 1;
    section {
      height: 100% !important;
    }
  }

  &__qr-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: rgb(255, 255, 255);
  }

  &__card-container {
    flex-grow: 1;
  }
  &__back-btn {
    border: none;
    background: rgb(255, 255, 255);
    color: rgb(49, 49, 49);
    padding: 0.5rem 0.7rem;
    margin: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-start;
    border-radius: 10px;
    &:active,
    &:focus {
      outline: none;
    }
    &:hover {
      transition: all 0.5s;
      box-shadow: 1px 1px 5px 1px rgb(227, 227, 227);
    }
  }

  &__back-btn-label {
    font-size: 0.8rem;
  }
  &__back-icon-container {
  }
  &__back-icon {
    width: 1.3rem;
    height: 1.3rem;
  }
  &__card-response-container,
  &__card {
    width: 100%;
    height: 100%;
  }

  &__card-response-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  &__card-container-black {
    background: #000;
  }
  &__card-container-white {
    background: #fff;
  }
  &__card-response-white {
    color: rgb(217, 217, 217);
  }
  &__card-response-black {
    color: rgb(36, 36, 36);
  }

  &__card-instruction {
  }
  &__card-spiel {
  }

  &__card-response-with-logo-confirm-container {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    width: 90%;
    padding-top: 2rem;
    background-image: linear-gradient(
      rgb(226, 232, 240),
      rgb(243, 245, 248),
      rgb(255, 255, 255),
      rgb(255, 255, 255)
    );
    border-radius: 10px;
  }
  &__response-main-logo-confirm-container {
    width: 45%;
    height: 150px;
    border-radius: 10px;
    padding: 1rem;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 5px 0 10px 0 rgb(227, 227, 227);
  }
  &__response-main-logo-confirm {
    width: 100%;
  }
  &__response-confirm {
    font-size: 1.2rem;
    font-weight: 200;
    line-height: 1.2;
    text-align: center;
  }
  &__card-response-with-logo-done-container {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    width: 70%;
    padding-top: 1rem;
  }
  &__response-main-logo-done-container,
  &__response-main-logo-done {
    width: 100%;
  }
  &__response-allowed,
  &__response-not-allowed {
    text-align: center;
    font-size: 1.9rem;
  }
  &__response-allowed {
    color: rgb(36, 162, 36);
  }
  &__response-not-allowed {
    color: #dc3545;
  }
  &__response-done {
    font-size: 1.2rem;
    font-weight: 200;
    line-height: 1.2;
    text-align: center;
  }
  &__card-response-regular {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__card-btn {
    width: 90%;
    margin-bottom: 1.3rem;
    border: 1px solid rgb(24, 70, 123);
    border-radius: 5px;
    transition: all 0.1s;
    background: rgb(24, 70, 123);
    color: #fff;
    padding: 0.5rem 0;

    &:active,
    &:focus,
    &:hover {
      outline-offset: 2px;
      outline: 2px solid rgb(24, 70, 123);
    }
  }

  &__card-again-container {
  }
  &__card-btn-again {
  }

  &__card-confirm-container {
  }
  &__card-btn-confirm {
  }

  &__card-done-container {
  }
  &__card-btn-done {
  }

  &__card-warning-container {
    display: flex;
    gap: 1;
    width: 90%;
    margin-bottom: 0.6rem;
    background: rgb(239, 246, 255);
    border-radius: 5px;
    overflow: hidden;
    color: rgb(28, 78, 216);
    overflow: hidden;
  }
  &__card-warning-icon-container {
    width: 20%;
    background: rgb(219, 234, 254);
    display: flex;
    justify-content: center;
  }
  &__card-warning-icon {
    width: 50%;
  }
  &__card-warning-message {
    margin-bottom: 0;
    padding: 0.5rem 0.8rem;
    line-height: 1.2;
    font-weight: 200;
  }

  //MEDIA QUERIES
  @media (max-width: "500px") {
    &__inner-container {
      width: 100%;
    }
  }
}
