@mixin card-container-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  margin-bottom: 0;
}

.landing__how-travelers {
  &-parent-container {
    background: #f68f5312;
    padding: 6rem 0;
  }
  &-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  &-title {
    margin-bottom: 2rem !important;
  }
  &-cards-container--1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;

    figure {
      width: 100%;
      @include card-container-display;
      &:first-child figcaption {
        word-break: break-all;
      }
      &:last-child figcaption {
        width: 30%;
      }
    }
  }

  &-cards-container--2 {
    display: flex;
    justify-content: center;
    gap: 4rem;

    figure {
      @include card-container-display;
      width: 40%;
      figcaption {
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  &-card-img {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  // &-card-caption-container{}
  // &-card-caption-subtitle{}

  @media only screen and (max-width: 1100px) {
    &-cards-container--1 {
      figure {
        &:last-child figcaption {
          width: 100% !important;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    &-cards-container--1 {
      grid-template-columns: 1fr;
      justify-items: center;

      figure {
        width: 90%;
        figcaption {
          width: 60%;
        }
      }
    }

    &-cards-container--2 {
      flex-direction: column;
      align-items: center;
      // gap: 3rem;
      figure {
        width: 90%;
        figcaption {
          width: 70%;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    &-cards-container--1 {
      figure {
        width: 100%;
        figcaption {
          width: 100%;
        }
      }
    }

    &-cards-container--2 {
      figure {
        width: 100%;
        figcaption {
          width: 100%;
        }
      }
    }

    &-card-img {
      margin-bottom: 1.5rem;
    }

    &-parent-container {
      padding: 4rem 0;
    }
  }
}
