$orange: #f68a1f;
$orange-dark: #c96501;

@import "./nav.scss";
@import "./learn.scss";
@import "./pre-footer.scss";
@import "./footer.scss";

@import "./home/header.scss";
@import "./home/why.scss";
@import "./home/register.scss";

@import "./register/register-steps.scss";
@import "./register/travelers-steps.scss";
@import "./register/business-steps.scss";

@import "./faq/faq.scss";

.landing {
  &-hover-zoom {
    transition: all 0.3s;
    &:hover {
      transform: scale(1.04);
    }
  }
  &-center {
    margin: 0 auto;
  }
  &-container {
    width: 85%;
    margin: 0 auto;
  }
  &-text-center {
    text-align: center;
  }
  &-heading-1 {
    font-size: 2.9rem;
    color: #313131;
    width: fit-content;
    margin: 0 auto;
    position: relative;
    text-align: center;
    transition: all 0.4s;

    &:hover {
      text-shadow: 0.5px 0.5px 1px $orange;
      &::after {
        width: 100%;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -1rem;
      transform: translate(-50%, 0);
      width: 125px;
      height: 2.5px;
      background: $orange;
      transition: width 0.5s;
    }
  }
  &-heading-4 {
    color: #313131;
    font-size: 1.5rem;
    margin-bottom: 0.9rem;
  }
  &-paragraph {
    color: #565656;
    font-size: 1rem;
    font-weight: 100;
    letter-spacing: 0.5px;
    margin-bottom: 0;
    // word-break: break-all;
  }
  &-btn {
    border-radius: 3rem;
    border: none;
    padding: 0.5rem 1.7rem;
    font-size: 1.1rem;
    background: $orange;
    color: #fff;
    font-weight: 100 !important;
    transition: all 0.1s;

    &::after {
      content: "";
      display: inline-block;
      transition: all 0.2s;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    &:hover {
      // background: #f9a048;
      text-decoration: none !important;
      color: #fff;
      &::after {
        border: solid #fff;
        margin-left: 0.4rem;
        border-width: 0 1.6px 1.6px 0;
        padding: 4px;
      }
    }

    // &:focus {
    //   outline-color: $orange;
    // }

    &:active {
      transform: scale(0.95);
    }
  }

  &__orange-bg-container {
    background: linear-gradient(to right, #fff8f0, #fcd4acc0, #f68a1f3d);
    height: 300px;
    padding-top: 10rem;
  }
  &__orange-bg-title {
    font-size: 4rem;
    font-weight: 500;
    margin-left: 17vw;
    color: #111111;
  }

  &__register-subtitle-container {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    word-spacing: 0.3rem;
    margin-bottom: 2.5rem;
    img {
      width: 2rem;
    }
  }

  &__register-subtitle {
    font-size: 2rem;
    font-weight: 300;
    color: #151b46;
    margin-bottom: 0;
  }

  // MEDIA QUERIES
  @media only screen and (max-width: 1300px) {
    &__orange-bg-title {
      width: 85%;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 900px) {
    &-heading-1 {
      font-size: 2.5rem;
    }
    &-heading-4 {
      font-size: 1.4rem;
    }
    &__orange-bg-title {
      font-size: 3.2rem;
    }
  }

  @media only screen and (max-width: 700px) {
    &-heading-1 {
      font-size: 1.8rem;
    }
    &-heading-4 {
      font-size: 1.3rem;
    }

    &-btn {
      padding: 0.4rem 1.3rem;
      font-size: 1rem;
    }

    &__register-subtitle-container {
      img {
        width: 1.6rem;
      }
    }
    &__register-subtitle {
      font-size: 1.6rem;
    }
    &__orange-bg-title {
      font-size: 3rem;
    }

    &__orange-bg-container {
      height: 260px;
      padding-top: 9.5rem;
    }
  }

  @media only screen and (max-width: 600px) {
    &-heading-1 {
      font-size: 1.5rem;
    }
    &-heading-4 {
      font-size: 1.2rem;
    }
    &-paragraph {
      line-height: 1.1;
    }
    &-btn {
      padding: 0.2rem 1rem;
      font-size: 0.8rem;
      &:hover {
        &::after {
          padding: 2.5px;
        }
      }
    }

    &__register-subtitle-container {
      margin-bottom: 1.8rem;
    }

    &__orange-bg-title {
      font-size: 2rem;
    }

    &__orange-bg-container {
      height: 230px;
      padding-top: 9rem;
    }
  }
}
