.landing__how-business {
  &-container {
    padding: 6rem 0;
  }
  &-title {
    margin-bottom: 2rem !important;
  }
  &-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6rem;
    figure:first-child {
      p {
        word-break: break-all;
      }
      img {
        width: 80%;
      }
    }
    figure:nth-child(2) {
      img {
        width: 70%;
      }
    }
  }
  &-card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
  }
  &-card-img {
    width: 60%;
    margin-bottom: 2rem;
  }

  //MEDIA QUERIES
  @media only screen and (max-width: 1000px) {
    &-cards-container {
      gap: 3rem;
    }
  }
  @media only screen and (max-width: 900px) {
    &-cards-container {
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &-card-container {
      width: 80%;
    }

    &-title {
      margin-bottom: 5rem !important;
    }
    &-card-caption-container {
      width: 80%;
    }
  }

  @media only screen and (max-width: 600px) {
    &-container {
      padding: 4rem 0;
    }
    &-card-container {
      width: 100%;
    }
    &-card-caption-container {
      width: 100%;
    }
    &-card-img {
      margin-bottom: 1.5rem;
    }
  }
}
