.landing__how-faq {
  &-container {
    padding: 6rem 0;
  }
  &-content-container {
    width: 90%;
    display: flex;
    gap: 4rem;
    justify-content: space-between;
  }

  &-installation-container {
    width: 100% !important;
    justify-content: unset;
  }

  &-ul-container {
    display: flex;
    gap: 2rem;
    margin-bottom: 0;
    border-bottom: 2px solid #cbcbcbcc;
    padding-left: 0;
  }
  &-li {
    list-style: none;
  }

  &-btn {
    font-size: 1.3rem;
    border: none;
    background: none;
    padding: 1rem;
    margin-bottom: -0.14rem;
    color: #3f3f3fcc;
    &:focus {
      outline: none;
    }
  }

  &-btn-active {
    color: #000000;
    border-bottom: 2px solid #000000 !important;
  }

  &-content-container {
    padding: 3rem 1rem;
  }
  &-content-column-container {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    flex-basis: 43%;
  }

  &-title {
    color: #f04f23;
    font-size: 1.4rem;
    margin-bottom: 1.3rem;
    line-height: 1.4;
  }
  &-subtitle {
    font-size: 1.1rem;
    color: #000000;
  }
  &-paragraph {
    font-size: 1.1rem;
    font-weight: 100;
    letter-spacing: 0.4px;
    color: #646464cc;
    margin-bottom: 0.3rem;
  }
  &-line-height {
    line-height: 1.1;
  }

  // MEDIA QUERIES
  @media only screen and (max-width: 1000px) {
    &-ul-container {
      flex-direction: column;
      gap: 0;
      border: none;
      li:last-child {
        button {
          border-color: #cbcbcbcc;
        }
      }
    }

    &-btn {
      width: 100%;
      margin-bottom: 0;
      text-align: start;
      border-bottom: 2px solid transparent;
      padding: 0.5rem 1rem;
    }
    &-content-container {
      width: 100% !important;
      flex-direction: column;
      gap: 1rem;
    }
  }

  @media only screen and (max-width: 600px) {
    &-container {
      padding: 4rem 0;
    }
    &-title {
      line-height: 1.2;
      margin-bottom: 0.8rem !important;
    }
  }
}
