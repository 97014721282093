.landing__learn {
  &-container {
    height: 600px;
    width: 100%;
    background-image: url("../../../assets/img/landing/home/learn/tree-mountain.png"),
      linear-gradient(to right, #fff8f0, #fcd4acc0, #f68a1f3d);
    background-size: cover;
    background-position: bottom;
  }
  &-img-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80%;
    gap: 3rem;
    padding-top: 6rem;
    button {
      padding: 0.5rem 3rem;
    }
  }

  &-img {
    width: 40%;
  }
  &-content {
    &-container {
      flex-grow: 1;
    }
    &-title {
      font-size: 2.9rem;
      color: #202a36;
      // font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
      margin-bottom: 1.6rem;
      transform: scale(1, 0.9);
      font-weight: 400;
      span {
        color: $orange;
        font-weight: 500;
      }
    }
  }

  // MEDIQ QUERIES
  @media only screen and (max-width: 900px) {
    &-container {
      height: auto;
    }
    &-img-container {
      flex-direction: column;
      padding: 6rem 0;
    }
    &-img {
      width: 80%;
    }
    &-content-title {
      font-size: 2.5rem;
    }
  }
  @media only screen and (max-width: 900px) {
    &-content-title {
      font-size: 2rem;
    }
  }
  @media only screen and (max-width: 600px) {
    &-img-container {
      padding: 3rem 0;
      gap: 1.5rem;
    }
    &-img {
      width: 100%;
    }
    &-content-title {
      font-size: 1.6rem;
    }
  }
}
