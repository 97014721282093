@font-face {
  font-family: "Rubik";
  src: local("Rubik Light Italic"), local("Rubik-LightItalic"),
    url("Rubik-LightItalic.woff2") format("woff2"),
    url("Rubik-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik Black"), local("Rubik-Black"),
    url("Rubik-Black.woff2") format("woff2"),
    url("Rubik-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik Medium Italic"), local("Rubik-MediumItalic"),
    url("Rubik-MediumItalic.woff2") format("woff2"),
    url("Rubik-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik Bold"), local("Rubik-Bold"),
    url("Rubik-Bold.woff2") format("woff2"),
    url("Rubik-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik Bold Italic"), local("Rubik-BoldItalic"),
    url("Rubik-BoldItalic.woff2") format("woff2"),
    url("Rubik-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik Black Italic"), local("Rubik-BlackItalic"),
    url("Rubik-BlackItalic.woff2") format("woff2"),
    url("Rubik-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik Medium"), local("Rubik-Medium"),
    url("Rubik-Medium.woff2") format("woff2"),
    url("Rubik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik Regular"), local("Rubik-Regular"),
    url("Rubik-Regular.woff2") format("woff2"),
    url("Rubik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik Italic"), local("Rubik-Italic"),
    url("Rubik-Italic.woff2") format("woff2"),
    url("Rubik-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik Light"), local("Rubik-Light"),
    url("Rubik-Light.woff2") format("woff2"),
    url("Rubik-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
