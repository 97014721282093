@mixin title-style {
  margin-bottom: 0;
  color: #000;
  font-size: 1.3rem;
  font-weight: 400;

  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
}
.landing__prefooter {
  &-title-parent-container {
    background: $orange;
    padding: 0.7rem 0;
  }
  &-title-container,
  &-logo-parent-container {
    display: flex;
    align-items: center;
    gap: 3.5rem;
    h6 {
      @include title-style;
    }
  }
  &-logo-parent-container {
    padding: 2.5rem 0;
    figcaption {
      display: none;
    }
  }
  &-logo-container--1,
  &-title--1 {
    flex-basis: 45%;
  }
  &-logo-container--2,
  &-title--2 {
    flex-basis: 27.5%;
  }
  &-logo-container--3,
  &-title--3 {
    flex-basis: 15%;
  }
  &-logo {
    width: 100%;
  }
  &-main-logo-container {
    width: 180px;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  &-main-logo {
    width: 100%;
  }
  &-links-container {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 0;
    padding-bottom: 2.5rem;
  }
  &-link {
    list-style: none;
    font-size: 1.3rem;
    font-weight: 100;
    color: #000;
    cursor: pointer;
    a {
      transition: all 0.3s;
      &:hover {
        text-decoration: none;
        color: $orange;
      }
    }
  }

  // MEDIA QUERIES
  @media only screen and (max-width: 900px) {
    &-title-parent-container {
      display: none;
    }
    &-logo-parent-container {
      flex-direction: column;
      width: 100% !important;
      margin: 0 !important;
      padding-top: 0;
      figure {
        width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        figcaption {
          display: unset;
          @include title-style;
          text-align: center;
          margin-bottom: 2rem;
          background: $orange;
          padding: 0.7rem 0;
          align-self: stretch;
        }
        img {
          width: 60%;
          margin: 0 auto !important;
        }
      }
    }
    &-links-container {
      padding-bottom: 1.5rem;
    }
  }
  @media only screen and (max-width: 600px) {
    &-logo-parent-container {
      gap: 1.5rem;
    }
    &-logo {
      width: 85% !important;
    }
    &-main-logo-container {
      width: 120px;
      margin-bottom: 0.8rem;
    }
    &-links-container {
      width: 85%;
      justify-content: center;
      gap: 1rem;
    }
    &-link {
      font-size: 0.9rem;
    }
  }
}
