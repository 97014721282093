.filter-wrapper {
  position: relative;
  border: 1px solid #efeff5;
  border-radius: 5px;
  padding: 0.25rem 68px 0.25rem 1.8rem;
  min-height: calc( 1.5em + 0.75rem + 2px);
  overflow: hidden;
}
.filter-wrapper .icon {
  position: absolute;
  height: 100%;
  left: 8px;
  top: 0px;
  display: flex;
  align-items: center;
}

.filter-wrapper .action {
  position: absolute;
  height: 100%;
  right: 2px;
  top: 0px;
  display: flex;
  align-items: center;
}

.filter-wrapper .action .btn {
  min-width: 60px;
}

.filter-wrapper .fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  background: #fff;
  font-size: 12px;
  font-style: italic;
  color: #adb5bd;
}

.filter-wrapper .badges {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -0.25rem;
}

.filter-wrapper .badges .badge {
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.filter-popover {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
}

.filter-popover .panel {
  background: #fff;
  width: 100%;
  max-width: 420px;
  padding: 1rem;
  border: 1px solid #efeff5;
  border-radius: 5px;
  box-shadow: 0px 4px 10px 0px #00000014;
}

.filter-wrapper .search {
  border: none;
  width: 100%;
  position: relative;
  top: 1px;
}
.filter-wrapper .search:focus {
  outline: none;
}