.landing__how-register {
  &-container {
    padding: 6rem 0;
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }

  &-title {
    margin-bottom: 1rem !important;
  }

  &-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8rem;
    figure:first-child figcaption {
      word-break: break-all;
    }
    figure:nth-child(2) figcaption {
      width: 70%;
      margin: 0 auto;
    }
  }
  &-card-img-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0;
  }
  &-card-img {
    width: 100%;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 1200px) {
    &-cards-container {
      figure:nth-child(2) figcaption {
        width: 100%;
        margin: unset;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    &-cards-container {
      gap: 3rem;
    }
  }

  @media only screen and (max-width: 900px) {
    &-cards-container {
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &-card-img-container {
      width: 60%;
    }
  }

  @media only screen and (max-width: 600px) {
    &-cards-container {
      gap: 2rem;
    }
    &-card-img-container {
      width: 100%;
    }
    &-card-img {
      margin-bottom: 1.5rem;
    }
    &-card-caption-subtitle {
      margin-bottom: 0.5rem !important;
    }
    &-container {
      padding: 4rem 0;
    }
  }
}
