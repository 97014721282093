.landing__home {
  &-header-container {
    position: relative;
    padding-top: 8rem;
    // margin-bottom: 5rem!important;
    min-height: 70vh;
  }
  &-description {
    &-container {
      margin-top: 1rem !important;
      width: 903px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-logo-container {
      width: 70%;
    }
    &-logo {
      width: 100%;
    }

    &-primary {
      font-size: 1.5rem;
      font-weight: 400;
      color: #313131;
      margin-top: 1rem;
    }
    &-secondary {
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      font-weight: 300;
      color: #173a63;
      margin-bottom: 1.2rem;
    }
    &-line {
      width: 1.5px;
      height: 30px;
      background: #000;
      margin: 0 1rem;
    }
  }

  &-header-island-img-container {
    width: 100%;
  }
  &-header-island-img {
    width: 100%;
  }
  &-header-floater-img-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 95%;
    height: auto;
  }
  &-header-floater-img {
    width: 100%;
    height: 100%;
  }

  // MEDIA QUERIES
  @media only screen and (max-width: 1000px) {
    &-header-container {
      margin-bottom: 0 !important;
    }
    &-description {
      &-container {
        width: 100%;
      }
      &-logo-container {
        width: 80%;
      }
    }

    &-nav-container {
      padding: 1rem 0;
    }

    &-nav-logo-container {
      width: 3rem;
    }
  }
  @media only screen and (max-width: 804px) {
    &-description {
      &-primary {
        font-size: 1.3rem;
      }
      &-secondary {
        font-size: 1.1rem;
        font-weight: 300;
        color: #173a63;
      }
    }
  }

  @media only screen and (max-width: 693px) {
    &-description {
      &-logo-container {
        width: 95%;
      }
      &-line {
        height: auto;
        align-self: stretch;
      }
      &-secondary {
        line-height: 1.2;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    &-header-container {
      min-height: unset;
    }
    &-description {
      &-primary {
        font-size: 0.9rem;
        line-height: 1.2;
        margin-top: 0.5rem;
      }
      &-secondary {
        font-size: 0.7rem;
      }
    }
  }
}
