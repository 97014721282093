.landing__home-register {
  &-container {
    padding-bottom: 8rem;
  }
  &-title {
    margin-bottom: 2.5rem !important;
  }
  &-img-parent-container {
    display: grid;
    align-items: flex-end;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
  &-img-container {
    width: 100% !important;
  }
  &-img {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  &-img-1 {
    width: 55%;
  }

  &-img-content-title {
    margin-bottom: 2rem !important;
  }

  // MEDIA QUERIES
  @media only screen and (max-width: 900px) {
    &-title {
      margin-bottom: 6rem !important;
    }
    &-img-parent-container {
      grid-template-columns: 1fr;
      gap: 6rem;
    }
  }
  @media only screen and (max-width: 443px) {
    &-title {
      margin-bottom: 3.5rem !important;
    }
  }
  @media only screen and (max-width: 400px) {
    &-img {
      width: 100%;
    }
    &-img-1 {
      width: 80%;
    }
  }
}
